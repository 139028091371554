<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-jumbotron>
                <h4>
                    <router-link :to="{ name: 'event_settings', params: { id: id } }" class="mr-2">
                        <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                    </router-link>
                    Ticket Settings
                </h4>
                <b-form class="mt-4">
                    <b-form-group
                        label="Max Event Tickets"
                    >
                        <b-input type="number" v-model.number="ticket_settings.max_event_tickets"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Max Tickets Per User"
                    >
                        <b-input type="number" v-model.number="ticket_settings.max_user_tickets"></b-input>
                    </b-form-group>
                    <b-checkbox v-model="ticket_settings.allow_user_ticket_requests_after_max" switch>
                        Allow additional ticket requests after user reaches ticket limit
                    </b-checkbox>
                    <b-checkbox v-model="ticket_settings.allow_user_ticket_requests_after_full" switch>
                        Allow additional ticket requests after full
                    </b-checkbox>
                    <b-checkbox v-model="ticket_settings.allow_ticket_removals" switch>
                        Allow ticket removal
                    </b-checkbox>
                    <b-checkbox v-model="ticket_settings.show_tickets_left" switch>
                        Show tickets left
                    </b-checkbox>
                    <b-checkbox v-model="ticket_settings.show_user_ticket_limit" switch>
                        Show user ticket limit
                    </b-checkbox>
                    <b-checkbox v-model="ticket_settings.send_tickets_email" switch>
                        Send email notification with tickets
                    </b-checkbox>
                    <div class="text-center mt-4">
                        <b-button
                            :disabled="saving"
                            variant="primary"
                            size="lg"
                            @click="saveTicketSettings()"
                            pill
                        >
                            <b-spinner v-if="saving" variant="light"></b-spinner>
                            <font-awesome-icon v-else icon="save"></font-awesome-icon>
                            Save Ticket Settings
                        </b-button>
                    </div>
                </b-form>
            </b-jumbotron>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
export default {
    props: ["id"],
    data() {
        return {
            ticket_settings : {},
            loading         : false,
            saving          : false
        };
    },
    mounted() {
        this.loadTicketSettings();
    },
    methods: {
        loadTicketSettings() {
            this.loading = true;
            this.$api.get(`/api/events/admin/${this.id}/fetch`).then((response) => {
                this.ticket_settings = response.data.event.ticket_settings;
                this.loading         = false;
            });
        },
        saveTicketSettings() {
            this.saving = true;
            this.$api.post(
                `/api/events/admin/ticketSettings/${this.id}/save`,
                this.ticket_settings
            ).then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.saving = false;
            });
        }
    }
}
</script>